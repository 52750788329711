import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import TxtImg from '../../components/TxtImg/TxtImg'
import Underline from '../../components/Underline/Underline'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

function AdvancingYears({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>Discomfort in advancing years - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Osteopathy and Advancing Years</h1>
            </Title>
            <section>
                <TxtImg split="third" imgFirst={true} imgSrc={data.image} imgAlt="Older people doing stretches">
                    <Underline>
                        <h2>Helping You Maintain Good Health in&nbsp;Later&nbsp;Life</h2>
                    </Underline>
                    <p>Osteopaths spend four to five years training to understand the relationship between the body’s organs, joints and muscles, and treat disorders of the musculoskeletal structures throughout the whole body.</p>
                    <p>As we get older our muscles, bones, ligaments and joints will also age. Ageing alone does not necessarily result in increased pain or stiffness. Some people with age-related pain find that treatment and advice from an osteopath complements GP care and pharmaceutical products. Osteopathic treatment and advice may help you feel better prepared to continue with, or adapt, your lifestyle as your body changes with time.</p>
                    <p>Osteopathic treatment is a hands-on approach offering a package of care that is personalised to you. If you begin to notice problems as you get older, your osteopath can work with you to keep you healthier, allowing you to enjoy the pleasures of life despite the natural aging process.</p>
                    <p>Arthritis is more common in older people. Although no one can cure arthritis, you may find that together you and your osteopath can discuss ways for keeping active, preventing common problems such as falls, and managing osteoporosis.</p>
                </TxtImg>
                <Text>
                    <Underline>
                        <h2>What Can You Do to Maintain Your Health as You Get Older?</h2>
                    </Underline>
                    <p>30 minutes of daily exercise (enough to make you warmer and breathe harder, whilst still able to have a conversation) can help reduce the risks associated with circulation problems and falls. It can also improve mood. This might include activities such as dancing or brisk walking.</p>
                    <p>Make sure you also eat a healthy, varied diet. The use of trainers or similar footwear can help absorb shocks and take the pressure off your knees, hips and spine when walking for longer periods.</p>
                    <p>A short rest can help recover energy for the remainder of the day’s activities. Although aches and pains may be a common element to ageing, they don’t have to get in the way of your lifestyle.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>Who Recognises Osteopathy?</h2>
                    </Underline>
                    <p>The UK National Institute for Health and Care Excellence recommends spinal manipulation for lower back pain. Osteopaths are trained to deliver such treatment and offer self-management advice.</p>
                    <p>The General Osteopathic Council regulates the practice of osteopathy in the UK, promoting patient safety by setting and monitoring standards of osteopathic conduct. By law, an osteopath must be registered and comply with strict regulatory requirements. This gives patients the same sort of guarantees and protection as those given by doctors and dentists.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        image: file(relativePath: {eq: "advancing-years.webp"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default AdvancingYears